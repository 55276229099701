import { Service } from 'typedi';

import { UniqueId } from '@domain/types';
import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { DirectConfigService } from '@app/services/DirectConfigService';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { UserPropertiesDto } from '@domain/models/createExperiment/userProperties/UserPropertiesDto';
import { directConfigDetailsSelectors } from '@infrastructure/store/directConfigDetails/directConfigDetailsSelectors';
import { ConfigService } from '@app/services/ConfigService';
import { DirectConfigDetailsMapper } from '@app/mappers/directConfig/DirectConfigDetailsMapper';
import { RunningFormParams } from '@pages/directConfigDetails/components/summary/config/runningForm/types/RunningFormParams';
import { DirectConfigState } from '@domain/enums/directConfig/DirectConfigState';
import { IUpdateDirectConfig } from '@domain/models/directConfig/UpdateDirectConfig';
import { ControlGroupByCountry } from '@domain/models/ControlGroup';
import { configSelectors } from '@infrastructure/store/config/configSelectors';

@Service()
export class DirectConfigDetailsService {
  constructor(
    private readonly directConfigService: DirectConfigService,
    private readonly configService: ConfigService,
    private readonly reduxRepo: ReduxRepo
  ) {}

  async get(id: UniqueId): Promise<IDirectConfig> {
    return this.directConfigService.get(id);
  }

  async fetchControlGroups(gameId: UniqueId): Promise<ControlGroupByCountry> {
    return this.configService.getControlGroups(gameId);
  }

  async updateInDev(form: InDevFormParams): Promise<IDirectConfig> {
    const directConfig = this.reduxRepo.findBy(directConfigDetailsSelectors.getDirectConfig);
    const defaultRegions = this.reduxRepo.findBy(directConfigDetailsSelectors.getDefaultRegions);
    const variables = this.reduxRepo.findBy(configSelectors.getVariableList);
    const S3Config = this.reduxRepo.findBy(configSelectors.getS3Config);

    const payload = DirectConfigDetailsMapper.inDevFormToUpdatePayload(
      form,
      directConfig,
      defaultRegions,
      variables,
      S3Config
    );

    return this.directConfigService.update(directConfig.id, payload);
  }

  async updateRunning(form: RunningFormParams): Promise<IDirectConfig> {
    const directConfig = this.reduxRepo.findBy(directConfigDetailsSelectors.getDirectConfig);

    const payload = DirectConfigDetailsMapper.runningFormToUpdatePayload(form, directConfig);

    return this.directConfigService.update(directConfig.id, payload);
  }

  async stateUpdate(newState: DirectConfigState): Promise<IDirectConfig> {
    const directConfig = this.reduxRepo.findBy(directConfigDetailsSelectors.getDirectConfig);

    const payload: Partial<IUpdateDirectConfig> = { newState };

    return this.directConfigService.update(directConfig.id, payload);
  }

  async fetchUserProperties(directConfig: IDirectConfig): Promise<UserPropertiesDto> {
    const { game } = directConfig;

    return this.configService.getUserProperties(game.firebaseProjectId);
  }
}
