import React from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { FormComponent } from '@ui/hooks/form';

import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';

import { TRow } from './TRow';

type Props = Pick<FormComponent<ExpGroupsConfigParams>, 'register' | 'errors'> & {
  editorEnabled: boolean;
  variables: string[];
  isAB: boolean;
  control: Control<ExpGroupsConfigParams>;
};

export function TBody({ editorEnabled, register, errors, control, variables, isAB }: Props) {
  const { fields } = useFieldArray({
    control,
    name: 'groups'
  });

  return (
    <tbody>
      {fields.map((field, index) => (
        <TRow
          key={field.id}
          field={field}
          editorEnabled={editorEnabled}
          index={index}
          register={register}
          errors={errors}
          variables={variables}
          isAB={isAB}
          control={control}
        />
      ))}
    </tbody>
  );
}
