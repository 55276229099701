import { IsArray, IsBoolean, IsNotEmpty, IsNumberString, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { cloneDeep } from 'lodash-es';

import { FormSummary } from '@pages/createExperiment/atoms/Summary/Summary';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';

export class GLDFormInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | string[] | null;
}

export class GLDFormConfig {
  constructor(name: string, input: GLDFormInput[], description: string) {
    this.name = name;
    this.input = input;
    this.description = description;
  }

  name: string;
  description: string;
  input: GLDFormInput[];
}

export class Param {
  @IsString()
  @IsNotEmpty()
  value: string;
}

export class GLDConfigParams {
  @IsString()
  defaultValue: string;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Param)
  params: Param[];

  @IsArray()
  config: GLDFormConfig[];

  @IsBoolean()
  cloneControlGroup: boolean;

  @IsBoolean()
  adjustableUsersAllocation: boolean;

  @IsBoolean()
  newUsers: boolean;

  @IsBoolean()
  sticky: boolean;

  @IsNumberString()
  usersAllocationPercent: string;

  static ofInitial() {
    const form = new GLDConfigParams();
    form.defaultValue = '';
    form.params = [];
    form.cloneControlGroup = false;
    form.config = [
      {
        name: 'A',
        description: 'Control Group',
        input: []
      }
    ];
    form.adjustableUsersAllocation = false;
    form.newUsers = true;
    form.sticky = true;
    form.usersAllocationPercent = '100';
    return form;
  }

  static getDefaultValuesByObjectiveConfig(
    objectiveGLDConfig: GLDConfigParams,
    isClone: boolean,
    userProperties: UserPropertiesParams,
    importedSegments?: string
  ): GLDConfigParams {
    const config = cloneDeep(objectiveGLDConfig);
    const isImportedSegments = Boolean(importedSegments);
    const isUserProperties = Boolean(userProperties?.inputs.length);

    if (!isClone && (isImportedSegments || isUserProperties)) {
      config.newUsers = false;
      config.sticky = false;
    }

    return config;
  }

  getSummary(isAdmin: boolean): FormSummary {
    const items = [
      { title: 'Clone Control Group', value: String(this.cloneControlGroup) },
      { title: 'Adjustable Percent', value: String(this.adjustableUsersAllocation) },
      { title: 'New Users', value: String(this.newUsers) },
      { title: 'Percentage of engaged users', value: `${this.usersAllocationPercent}%` }
    ];

    if (isAdmin) {
      items.push({ title: 'Sticky', value: String(this.sticky) });
    }

    return items;
  }
}
