import { InputGroupSessionForm, ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';
import { NotFoundError } from '@app/errors/NotFoundError';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ObjectiveFormatter } from '@app/mappers/experiment/ObjectiveFormatter';
import { ExperimentVariableType } from '@domain/enums/ExperimentVariableType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { ExperimentVariableInputMode } from '@domain/enums/ExperimentVariableInputMode';
import { GLDConfigParams, GLDFormConfig, GLDFormInput, Param } from '@domain/models/createExperiment/GLDConfigParams';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import {
  ExperimentObjectiveControlGroup,
  ExperimentObjectiveDto,
  ExperimentObjectiveInput
} from '@domain/models/experiment/ExperimentObjectiveDto';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { ExperimentType } from '@domain/enums/ExperimentType';
import { S3ConfigState } from '@infrastructure/store/config/reducers/fileUploadReducer';

export class ObjectiveMapper {
  static mapVariableTypeToWrapperType(key: ExperimentVariableType): ExperimentVariableWrapper {
    const value = {
      [ExperimentVariableType.STRING]: ExperimentVariableWrapper.STRING,
      [ExperimentVariableType.BOOLEAN]: ExperimentVariableWrapper.BOOLEAN,
      [ExperimentVariableType.INTEGER]: ExperimentVariableWrapper.INT,
      [ExperimentVariableType.INT_LIST]: ExperimentVariableWrapper.INT_LIST
    }[key];

    if (!value) {
      throw new TypeError(`Cannot map "${key}" to "ExperimentVariableWrapper"`);
    }

    return value;
  }

  static mapVariableTypeToInputModeType(key: ExperimentVariableType): ExperimentVariableInputMode {
    const value = {
      [ExperimentVariableType.STRING]: ExperimentVariableInputMode.STRING,
      [ExperimentVariableType.BOOLEAN]: ExperimentVariableInputMode.BOOLEAN,
      [ExperimentVariableType.INTEGER]: ExperimentVariableInputMode.INT,
      [ExperimentVariableType.INT_LIST]: ExperimentVariableInputMode.INT_LIST
    }[key];

    if (!value) {
      throw new TypeError(`Cannot map "${key}" to "ExperimentVariableInputMode"`);
    }

    return value;
  }

  static mapControlGroup(form: ObjectiveConfigParams, variables: ExperimentVariableDto[]): Record<string, object> {
    const { controlGroup, params } = form;

    return controlGroup.reduce((acc, session, index) => {
      // Filter only selected ad profile parameters
      const filteredGroup = Object.fromEntries(Object.entries(session).filter(([key]) => params.includes(key)));

      Object.entries(filteredGroup).forEach(([key, value]) => {
        const currentVariable = variables.find(({ name }) => name === key);

        if (!currentVariable) {
          throw new NotFoundError('ExperimentVariableDto');
        }

        const { sessionsSupported, type } = currentVariable;

        const sessionType = currentVariable.sessionsSupported
          ? ExperimentObjectiveSessionType.SESSION
          : ExperimentObjectiveSessionType.PLAIN;
        const wrapperType = this.mapVariableTypeToWrapperType(type);

        const formattedValue = ObjectiveFormatter.formatControlValue(wrapperType, value);

        if (!acc[key]) {
          acc[key] = {
            configValue: {},
            type: sessionType
          };
        }

        if (sessionsSupported) {
          acc[key].configValue[session.sessionIndex] = formattedValue;
        } else {
          acc[key].configValue = formattedValue;
        }
      });

      return acc;
    }, {} as Record<string, { configValue: object; type: ExperimentObjectiveSessionType }>);
  }

  // todo test
  static mapInput(form: ObjectiveConfigParams, variables: ExperimentVariableDto[]): object[] {
    const { controlGroup, params, input } = form;

    const formattedInputControlGroup = controlGroup.reduce(
      (acc, session, index) => {
        // Filter only selected ad profile parameters
        const filteredGroup = Object.fromEntries(Object.entries(session).filter(([key]) => params.includes(key)));

        Object.entries(filteredGroup).forEach(([key, value]) => {
          const currentVariable = variables.find(({ name }) => name === key);

          if (!currentVariable) {
            throw new NotFoundError('ExperimentVariableDto');
          }

          const { sessionsSupported, type } = currentVariable;

          const inputType = this.mapVariableTypeToInputModeType(type);
          const { sessionIndex } = session;
          const formattedValue = ObjectiveFormatter.formatInputValue(inputType, value);

          // update sessionsInputs
          if (sessionsSupported) {
            // init value for new session
            if (!acc.sessionsInputs[sessionIndex]) {
              acc.sessionsInputs[sessionIndex] = {};
            }

            acc.sessionsInputs[sessionIndex][key] = [formattedValue];
          } else {
            if (!acc.plainInputs[key]) {
              acc.plainInputs[key] = [formattedValue];
            }
          }
        });

        return acc;
      },
      { sessionsInputs: {}, plainInputs: {} }
    );

    // 3. Map input to input payload
    const formattedInput = input.map(({ section }) => {
      return section.reduce(
        (acc, session, index) => {
          // Filter only selected ad profile parameters
          const filteredGroup = Object.fromEntries(Object.entries(session).filter(([key]) => params.includes(key)));

          Object.entries(filteredGroup).forEach(([key, value]) => {
            const currentVariable = variables.find(({ name }) => name === key);

            if (!currentVariable) {
              throw new NotFoundError('ExperimentVariableDto', key);
            }

            const { sessionsSupported, type } = currentVariable;

            const inputType = this.mapVariableTypeToInputModeType(type);
            const { sessionIndex } = session;

            const formattedValue = ObjectiveFormatter.formatInputValue(inputType, value);

            // update sessionsInputs
            if (sessionsSupported) {
              // init value for new session
              if (!acc.sessionsInputs[sessionIndex]) {
                acc.sessionsInputs[sessionIndex] = {};
              }
              // init value for new session
              if (!acc.sessionsInputs[sessionIndex][key]) {
                acc.sessionsInputs[sessionIndex][key] = [formattedValue].flat();
              }
            } else {
              if (!acc.plainInputs[key]) {
                acc.plainInputs[key] = [formattedValue].flat();
              }
            }
          });

          return acc;
        },
        { sessionsInputs: {}, plainInputs: {} }
      );
    });

    return [formattedInputControlGroup, ...formattedInput];
  }

  static mapGLDConfigs(input: GLDFormConfig[], S3Config: S3ConfigState): GenericConfigEntry[] {
    return input.map((item) => {
      const config = new GenericConfigEntry();
      let files: string[] | undefined = undefined;

      const entry = item.input.reduce((acc, { key, value }) => {
        if (value === null) {
          return acc;
        }

        if (key === 'files') {
          // for now only files can be an array
          const isValidValue = Array.isArray(value) && value.length;

          if (isValidValue) {
            files = value.map((filename) => {
              // cloned filename will always contain tempId and other params
              if (filename.split('/').length > 1) {
                return filename;
              }

              return `temp_${S3Config.tempS3Id}/${item.name}/${filename}`;
            });
          }

          return acc;
        }

        acc[key] = {
          type: ExperimentObjectiveSessionType.PLAIN,
          configValue: { type: ExperimentVariableWrapper.STRING, value }
        };

        return acc;
      }, {});

      config.name = item.name;
      config.description = item.description;
      config.active = true;
      config.entry = entry;

      if (files) {
        config.files = files;
      }

      return config;
    });
  }

  static mapObjControlGroupToForm(controlGroup: ExperimentObjectiveControlGroup): string {
    const { type, configValue } = controlGroup;
    const isSessionSupported = type === ExperimentObjectiveSessionType.SESSION;

    const config = isSessionSupported ? configValue[1] : configValue;
    switch (config.type) {
      case ExperimentVariableWrapper.BOOLEAN:
        return ObjectiveFormatter.formatBooleanWrapperToString(config.value);
      case ExperimentVariableWrapper.INT:
        return ObjectiveFormatter.formatIntWrapperToString(config.value);
      case ExperimentVariableWrapper.INT_LIST:
        return ObjectiveFormatter.formatIntListWrapperToString(config.value);
      case ExperimentVariableWrapper.STRING:
      default:
        return config.value;
    }
  }

  static mapObjectiveInputToFormInput(input: ExperimentObjectiveInput): InputGroupSessionForm[] {
    const { plainInputs, sessionsInputs } = input;
    const sessionInputsKeys = Object.keys(sessionsInputs);

    // in case when we don't have session supported values we need one iteration for plain inputs
    if (!sessionInputsKeys.length) {
      sessionInputsKeys.push('1');
    }

    return sessionInputsKeys.map((sessionIndex) => {
      const fields = { ...plainInputs, ...sessionsInputs[sessionIndex] };

      const payload = { sessionIndex };

      Object.keys(fields).forEach((param) => {
        const configs = fields[param];
        const isBoolean = configs[0].type === ExperimentVariableInputMode.BOOLEAN;

        // for boolean return just a value
        if (isBoolean) {
          payload[param] = ObjectiveFormatter.formatBooleanInputModeToValue(configs[0].value as boolean);
          // otherwise return array of values
        } else {
          payload[param] = configs.map((config) => {
            const { type, value, values } = config;

            switch (type) {
              case ExperimentVariableInputMode.INT:
                return ObjectiveFormatter.formatIntInputModeToValue(value as number);
              case ExperimentVariableInputMode.INT_LIST:
                return ObjectiveFormatter.formatIntListInputModeToValue(values as number[]);
              case ExperimentVariableInputMode.STRING:
              default:
                return value;
            }
          });
        }
      });

      return payload;
    });
  }

  static mapObjectiveInputsToFormInput(inputs: ExperimentObjectiveInput[]) {
    // exclude control group from inputs
    const inputsWithoutControlGroup = inputs.slice(1);

    return inputsWithoutControlGroup.map((input) => {
      return { section: ObjectiveMapper.mapObjectiveInputToFormInput(input) };
    });
  }

  static mapObjectiveDtoToForm(
    objective: ExperimentObjectiveDto,
    experimentVariables: ExperimentVariableDto[]
  ): ObjectiveConfigParams {
    const params = ObjectiveConfigParams.ofInitial();

    const controlGroup = Object.entries(objective.controlGroup).reduce(
      (acc, [param, value]) => {
        acc[param] = ObjectiveMapper.mapObjControlGroupToForm(value);

        return acc;
      },
      { sessionIndex: '1' }
    );

    const controlGroupKeys = Object.keys(objective.controlGroup);
    const experimentVariablesKeys = experimentVariables.map((dto) => dto.name);
    const filteredParams = controlGroupKeys.filter((key) => experimentVariablesKeys.includes(key));

    params.newUsers = objective.newUsers;
    params.sticky = objective.sticky;
    params.cloneControlGroup = objective.cloneControlGroup;
    params.usersAllocationPercent = objective.usersAllocationPercent.toString();
    params.builtInGroup = objective.builtInGroup;
    params.adjustableUsersAllocation = objective.adjustableUsersAllocation;
    params.params = filteredParams;
    params.controlGroup = [controlGroup];
    params.input = ObjectiveMapper.mapObjectiveInputsToFormInput(objective.input);

    return params;
  }

  static mapExpDtoToABObjectiveConfig(
    experiment: ExperimentDto,
    experimentVariables: ExperimentVariableDto[]
  ): Record<string, ObjectiveConfigParams> {
    // we need to store one default objective config params for case when we can't match exist region and use this one
    const [defaultObjective] = experiment.experimentObjectives;
    const defaultObjectiveConfigParams = ObjectiveMapper.mapObjectiveDtoToForm(defaultObjective, []);

    return experiment.experimentObjectives.reduce(
      (acc, objective) => {
        acc[objective.primaryRegion.name] = ObjectiveMapper.mapObjectiveDtoToForm(objective, experimentVariables);
        return acc;
      },
      { default: defaultObjectiveConfigParams }
    );
  }

  static mapConfigsToGLDForm(configs: GenericConfigEntry[]): GLDFormConfig[] {
    const params = new Set<string>();

    // collect all possible params, each config might have different setup of them
    configs.forEach(({ entry }) => {
      Object.keys(entry).forEach((key) => params.add(key));
    });

    // handle files param
    if (configs.some((config) => config.files?.length)) {
      params.add('files');
    }

    // for each config return proper form value
    return configs.map(({ name, description, entry, files }) => {
      const input = [] as GLDFormInput[];

      // when param is files we use files as value instead of entry
      params.forEach((param) => {
        if (param === 'files') {
          input.push({ key: 'files', value: files || null });
          return;
        }

        // when entry doesn't have value for particular param - we add it as null(need it for form)
        const value = entry[param] ? entry[param].configValue.value : null;

        input.push({ key: param, value } as GLDFormInput);
      });

      return { name, description: description || '', input };
    });
  }

  static mapConfigsToGLDParams(configs: GenericConfigEntry[]): Param[] {
    const params = new Set<string>();

    // collect all possible params, each config might have different setup of them
    configs.forEach(({ entry }) => {
      Object.keys(entry).forEach((key) => params.add(key));
    });

    if (configs.some((config) => config.files?.length)) {
      params.add('files');
    }

    // for each param return proper form value
    return Array.from(params).map((key) => ({ value: key }));
  }

  static mapExpDtoToGLDObjectiveConfig(experiment: ExperimentDto): GLDConfigParams {
    const params = GLDConfigParams.ofInitial();
    const { experimentType, experimentObjectives } = experiment;
    const isGLD = experimentType === ExperimentType.GLD_TEST;
    const [objective] = experimentObjectives;

    if (!isGLD) {
      return params;
    }

    params.adjustableUsersAllocation = objective.adjustableUsersAllocation;
    params.newUsers = objective.newUsers;
    params.sticky = objective.sticky;
    params.usersAllocationPercent = objective.usersAllocationPercent.toString();
    params.cloneControlGroup = objective.cloneControlGroup;
    params.params = ObjectiveMapper.mapConfigsToGLDParams(objective.configs);
    params.config = ObjectiveMapper.mapConfigsToGLDForm(objective.configs);

    return params;
  }
}
