import React, { useEffect } from 'react';

import { useDispatch, useSelector } from '@ui/hooks/redux';
import { useForm } from '@ui/hooks/form';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { setIterationFilters } from '@infrastructure/store/experimentDetails/experimentDetailsActions';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { Filters } from '@pages/experimentDetails/components/stats/components/result/components/filters/Filters';

export function FiltersContainer() {
  const dispatch = useDispatch();
  const filters = useSelector(experimentDetailsSelectors.getIterationFilters);
  const { iterationId } = useSelector(experimentDetailsSelectors.getSelectedIteration);
  const iterationResults = useSelector(experimentDetailsSelectors.getIterationResult);
  const isIAPExist =
    iterationResults.length > 0 &&
    iterationResults.every(
      ({ iapRevenueTotal, iapRevenuePerUser }) => Boolean(iapRevenuePerUser) && Boolean(iapRevenueTotal)
    );

  const { watch, control } = useForm<IterationRequestParams>({
    schema: IterationRequestParams,
    defaultValues: filters
  });

  useEffect(() => {
    const subscription = watch((value) => {
      dispatch(setIterationFilters(value as IterationRequestParams));
    });
    return () => subscription.unsubscribe();
  }, [dispatch, watch]);

  return <Filters control={control} disabled={!iterationId} isIAPExist={isIAPExist} />;
}
