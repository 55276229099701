import React from 'react';

import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';

import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { CONFIG_GROUP_NAME } from '@domain/enums/ConfigGroupName';

import { useSelector } from '@ui/hooks/redux';

import { THead } from './atoms/THead';
import { TBody } from './atoms/TBody';

import styles from './GLDFormSummary.module.scss';

export function GLDFormSummary() {
  const form = useSelector(createExperimentSelectors.getForm);
  const isAdmin = useSelector(authSelectors.getIsAdmin);

  const { config, usersAllocationPercent } = form[CreateExperimentForm.GLD_OBJECTIVE_CONFIG];
  const { primaryRegion } = form[CreateExperimentForm.TARGET_CONFIG];

  const groupCount = config.length;
  const allocationSummary = useSelector(
    createExperimentSelectors.getUserAllocationSummary(primaryRegion, groupCount, usersAllocationPercent)
  );

  const controlGroup = config.find(({ name }) => name === CONFIG_GROUP_NAME.CONTROL);
  const params = form[CreateExperimentForm.GLD_OBJECTIVE_CONFIG];
  const stepSummary = params.getSummary(isAdmin);

  const summary = [...stepSummary, ...allocationSummary];

  return (
    <div>
      <ul className={styles.summary}>
        {summary.map(({ title, value }, index) => {
          return (
            <li key={`summary_item_${index}`}>
              <p>
                {title}: <span>{value}</span>
              </p>
            </li>
          );
        })}
      </ul>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <THead controlGroup={controlGroup} />
          <TBody config={config} />
        </table>
      </div>
    </div>
  );
}
