import React from 'react';
import { Control, FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { GameConfigObjectiveForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { EditableCell } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/components/inputGroup/atoms/EditableCell';
import { FileUploadCellContainer } from '@components/forms/fileUploadCell/FileUploadCellContainer';

type Props = {
  control: Control<GameConfigObjectiveForm>;
  setFocus: UseFormSetFocus<GameConfigObjectiveForm>;
  configs: FieldArrayWithId<GameConfigObjectiveForm, 'config'>[];
};

export function TBody({ configs, control }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr key={config.id}>
          {config.input.map((input, inputIndex) => {
            const isFile = input.key === 'files';

            if (isFile) {
              return (
                <FileUploadCellContainer<GameConfigObjectiveForm>
                  key={input.key}
                  fieldName={`config.${configIndex}.input.${inputIndex}.value`}
                  control={control}
                  configName={config.name}
                />
              );
            }

            return <EditableCell key={input.key} control={control} configIndex={configIndex} inputIndex={inputIndex} />;
          })}
        </tr>
      ))}
    </tbody>
  );
}
