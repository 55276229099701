import React from 'react';
import cn from 'classnames';
import { Control, FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { ConfigName } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/atoms/ConfigName';
import { EditableDescription } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/EditableDescription';
import { EditableCell } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/EditableCell';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { ShutdownCheckbox } from '@pages/experimentDetails/components/summary/config/devPhase/sections/objectiveConfig/configInput/atoms/ShutdownCheckbox';
import { FileUploadCellContainer } from '@components/forms/fileUploadCell/FileUploadCellContainer';

import styles from '../ConfigInput.module.scss';

type Props = {
  control: Control<DevPhaseParams>;
  setFocus: UseFormSetFocus<DevPhaseParams>;
  configs: FieldArrayWithId<DevPhaseParams, 'objectiveConfig.config'>[];
  handleRemoveConfig: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled: boolean;
};

export function TBody({ configs, handleRemoveConfig, control, setFocus, isDisabled }: Props) {
  return (
    <tbody>
      {configs.map((config, configIndex) => (
        <tr className={cn(!config.active && styles.inactiveRow)} key={config.id}>
          <ConfigName
            name={config.name}
            configIndex={configIndex}
            handleRemoveConfig={handleRemoveConfig}
            isDisabled={isDisabled}
          />
          <ShutdownCheckbox control={control} configIndex={configIndex} isDisabled={isDisabled} />
          <EditableDescription
            control={control}
            configIndex={configIndex}
            setFocus={setFocus}
            isDisabled={isDisabled}
          />
          {config.input.map((input, inputIndex) => {
            const isFile = input.key === 'files';

            if (isFile) {
              return (
                <FileUploadCellContainer<DevPhaseParams>
                  key={input.key}
                  fieldName={`objectiveConfig.config.${configIndex}.input.${inputIndex}.value`}
                  control={control}
                  configName={config.name}
                  isDisabled={isDisabled}
                />
              );
            }

            return (
              <EditableCell
                key={input.key}
                control={control}
                configIndex={configIndex}
                inputIndex={inputIndex}
                inputName={input.key}
                isDisabled={isDisabled}
              />
            );
          })}
        </tr>
      ))}
    </tbody>
  );
}
