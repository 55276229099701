import React from 'react';
import { memoize } from 'proxy-memoize';

import { experimentDetailsSelectors } from '@infrastructure/store/experimentDetails/experimentDetailsSelectors';
import { ExpObjectiveSelect } from '@pages/experimentDetails/components/summary/config/objective/ExpObjectiveSelect';
import { useSelector } from '@ui/hooks/redux';

import styles from '../../../../ExperimentDetails.module.scss';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';

type Props = {
  handleSetObjective: (e) => void;
};

export function ExpObjectiveConfigSummary({ handleSetObjective }: Props) {
  const objectiveId = useSelector(experimentDetailsSelectors.getSelectedObjectiveId);
  const params = useSelector(memoize(experimentDetailsSelectors.getExpObjectiveConfigParams(objectiveId)));
  const regionName = useSelector(experimentDetailsSelectors.getObjectiveRegionNameById(objectiveId));
  const isAdmin = useSelector(authSelectors.getIsAdmin);

  const items = params.getSummary(isAdmin).map(({ title, value, key }, index) => {
    let valueText: string = '';

    switch (key) {
      case 'primaryRegion':
        valueText = regionName;
        break;
      case 'usersAllocationPercent':
        valueText = `${value}%`;
        break;
      default:
        valueText = value;
        break;
    }

    return (
      <li key={title}>
        {title}:<span>{valueText}</span>
      </li>
    );
  });

  return (
    <div className={styles.summaryListWrapper}>
      <ExpObjectiveSelect handleSet={handleSetObjective} />
      <ul className={styles.summaryList}>{items}</ul>
    </div>
  );
}
