import React from 'react';
import { useSelector } from '@ui/hooks/redux';

import { NotificationType } from '@pages/createExperiment/notifications/NotificationType';
import { createExperimentSelectors } from '@infrastructure/store/createExperiment/createExperimentSelectors';
import { Notification } from '@components/layout/overlapedNotifications/Notification';
import { CreateExperimentForm } from '@domain/enums/CreateExperimentForm';
import { ExperimentType } from '@domain/enums/ExperimentType';

import styles from '@components/layout/overlapedNotifications/Notification.module.scss';

const MAX_GLD_PARAMS = 12;

export function MaximumParamsContainer() {
  const objectiveConfigStepIndex = 3;
  const isObjectiveConfigStep = useSelector(createExperimentSelectors.isMatchStep(objectiveConfigStepIndex));
  const { experimentType } = useSelector(createExperimentSelectors.getForm)[CreateExperimentForm.BASIC_INFO];
  const isGameConfig = experimentType === ExperimentType.GLD_TEST;

  if (!isObjectiveConfigStep || !isGameConfig) {
    return null;
  }

  return (
    <Notification title="Parameters limit recommendation" type={NotificationType.INFO}>
      <div className={styles.paramsLimitContent}>
        Please don’t use more than {MAX_GLD_PARAMS} parameters in the configuration, otherwise there is a potential data
        corruption
      </div>
    </Notification>
  );
}
