import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { GameConfigForm } from '@pages/createDirectConfig/objectiveConfig/forms/gameConfig/types/GameConfigObjectiveForm';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentVariableWrapper } from '@domain/enums/ExperimentVariableWrapper';
import { S3ConfigState } from '@infrastructure/store/config/reducers/fileUploadReducer';

export class GameConfigFormMapper {
  static toGenericConfig(config: GameConfigForm[], S3Config: S3ConfigState): GenericConfigEntry {
    let files: string[] | null = null;

    const entry = config[0].input.reduce((acc, { key, value }) => {
      if (value === null) {
        return acc;
      }

      if (key === 'files') {
        // for now only files can be an array
        const isValidValue = Array.isArray(value) && value.length;

        if (isValidValue) {
          files = value.map((filename) => {
            // cloned filename will always contain tempId and other params
            if (filename.split('/').length > 1) {
              return filename;
            }

            return `temp_${S3Config.tempS3Id}/${config[0].name}/${filename}`;
          });
        }

        return acc;
      }

      acc[key] = {
        type: ExperimentObjectiveSessionType.PLAIN,
        configValue: { type: ExperimentVariableWrapper.STRING, value }
      };
      return acc;
    }, {});

    return {
      name: 'A',
      description: null,
      active: true,
      files: files || undefined,
      zipFileInfo: null,
      entry
    };
  }
}
