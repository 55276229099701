import { useCallback } from 'react';
import { Container } from 'typedi';

import { useSelector, useDispatch } from '@ui/hooks/redux';
import { useToggle } from '@ui/hooks/useToggle';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { authSelectors } from '@infrastructure/store/auth/authSelectors';
import { pushLogout } from '@infrastructure/store/auth/authActions';

import { SideMenu } from './SideMenu';
import { LocalStorageRepo, LocalStorageKey } from '@infrastructure/repositories/LocalStorageRepo';

const storage = Container.get(LocalStorageRepo);

export function SideMenuContainer() {
  const dispatch = useDispatch();

  const { isActive, handleClose, handleOpen } = useToggle();

  const isAuth = useSelector(authSelectors.getIsAuth);
  const profile = useSelector(authSelectors.getProfile);
  const isAdmin = useSelector(authSelectors.getIsAdmin);
  const appVersion = useSelector(configSelectors.getVersion);
  // TODO: Replace with hook
  const iconUrl = storage.findOne(LocalStorageKey.ICON_PATH);

  const handleLogout = useCallback(() => {
    dispatch(pushLogout.trigger());
  }, [dispatch]);

  return (
    <SideMenu
      isAuth={isAuth}
      profile={profile}
      appVersion={appVersion}
      iconUrl={iconUrl}
      handleLogout={handleLogout}
      handleOpen={handleOpen}
      handleClose={handleClose}
      showSideMenu={isActive}
      isAdmin={isAdmin}
    />
  );
}
