import { ArrayNotEmpty, IsArray, IsNotEmpty, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { cloneDeep, merge } from 'lodash-es';

import { IDirectConfig } from '@domain/models/directConfig/DirectConfig';
import { UserPropertiesParams } from '@domain/models/createExperiment/userProperties/UserPropertiesParams';
import { VersionInput } from '@domain/models/VersionInput';
import { RegionOption } from '@domain/models/createExperiment/TargetConfigParams';
import { VersionOperator } from '@domain/enums/VersionOperator';
import { DirectConfigType } from '@domain/enums/directConfig/DirectConfigType';
import { ControlGroupSessionForm } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { AdProfileFormMapper } from '@app/mappers/directConfig/AdProfileFormMapper';
import { ExperimentVariableDto } from '@domain/models/experimentVariable/ExperimentVariableDto';

export class InDevConfigFormInput {
  constructor(key: string, value: string | string[] | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | string[] | null;

  public setKey(key: string): InDevConfigFormInput {
    this.key = key;
    return this;
  }

  public setValue(value: string | null): InDevConfigFormInput {
    this.value = value;
    return this;
  }
}

export class InDevFormParams {
  constructor(directConfig?: IDirectConfig, variables?: ExperimentVariableDto[]) {
    if (!directConfig || !variables) {
      return this;
    }

    this.name = directConfig.name;
    this.type = directConfig.type;
    this.importedSegments = directConfig.importedSegments;
    this.liveRegions = directConfig.liveRegions.map(({ name }) => ({ value: name, label: name }));
    this.inDevRegions = directConfig.inDevRegions.map(({ name }) => ({ value: name, label: name }));
    this.versions = directConfig.appVersions.map((version) => ({ value: version }));
    this.appliedOperator = directConfig.appliedOperator;

    const initUserProperties: UserPropertiesParams = { inputs: [] };

    this.userProperties = directConfig.userProperties
      ? directConfig.userProperties.reduce((acc, property) => {
          acc.inputs.push({
            operatorId: property.operatorId.toString(),
            value: property.value,
            userPropertyId: property.userPropertyId.toString(),
            userPropertyDisplayName: property.userPropertyDisplayName,
            operatorDisplayName: property.operatorDisplayName
          });

          return acc;
        }, initUserProperties)
      : initUserProperties;

    const isAdProfile = directConfig.type === DirectConfigType.AD_PROFILE;

    if (isAdProfile) {
      this.params = Object.keys(directConfig.genericConfigList[0].entry).map((key) => key);
      this.controlGroup = AdProfileFormMapper.genericConfigToForm(directConfig.genericConfigList[0], variables);
    } else {
      this.config = Object.entries(directConfig.genericConfigList[0].entry).map(
        ([key, value]) => new InDevConfigFormInput(key, value.configValue.value as string | null)
      );

      if (Boolean(directConfig.genericConfigList[0].files)) {
        this.config.push(new InDevConfigFormInput('files', directConfig.genericConfigList[0].files || null));
      }
    }
  }

  @IsString()
  @IsNotEmpty()
  public name: string;

  type: DirectConfigType;

  @IsString()
  public importedSegments: string;

  @IsArray()
  @ArrayNotEmpty()
  liveRegions: RegionOption[];

  @IsArray()
  @ArrayNotEmpty()
  inDevRegions: RegionOption[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  versions: VersionInput[];

  appliedOperator: VersionOperator;

  userProperties: UserPropertiesParams;

  // used for Game Config type
  @IsArray()
  @ArrayNotEmpty()
  @ValidateIf((o) => o.type === DirectConfigType.GAME_CONFIG)
  config: InDevConfigFormInput[] = [];

  // used for Ad Profile type
  @IsArray()
  @ArrayNotEmpty()
  @ValidateIf((o) => o.type === DirectConfigType.AD_PROFILE)
  params: string[] = [];

  // used for Ad Profile type
  controlGroup: ControlGroupSessionForm[] = [{ sessionIndex: '1' }];

  public mergeControlGroup(controlGroup: ControlGroupSessionForm) {
    this.controlGroup = this.controlGroup.map((group) => {
      const refGroup = cloneDeep(controlGroup);

      return merge(refGroup, group);
    });
    return this;
  }
}
