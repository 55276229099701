import React from 'react';
import { Control, UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { FormComponent } from '@ui/hooks/form';

import { GLDConfigParams } from '@domain/models/createExperiment/GLDConfigParams';
import { GLDParamDto } from '@domain/enums/GLDParamDto';
import { GameInstallsStatsDto } from '@domain/models/game/GameInstallsStatsDto';
import { TargetConfigParams } from '@domain/models/createExperiment/TargetConfigParams';

import { PercentUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/PercentUsersInput';
import { AdjustableUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/AdjustablePersentUsersInput';
import { NewUsersInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/NewUsersInput';
import { InputGroup } from '@pages/createExperiment/objectiveConfig/forms/GLD/components/inputGroup/InputGroup';
import { NextButton } from '@components/layout/NextButton/NextButton';
import { UserAllocationFormBlock } from '@pages/createExperiment/objectiveConfig/atoms/UserAllocationFormBlock';
import { StickyInput } from '@pages/createExperiment/objectiveConfig/forms/GLD/inputs/StickyInput';

type Props = FormComponent<GLDConfigParams> & {
  control: Control<GLDConfigParams>;
  watch: UseFormWatch<GLDConfigParams>;
  setFocus: UseFormSetFocus<GLDConfigParams>;
  gameInstalls: GameInstallsStatsDto | null;
  minUsersPerGroup: number;
  gldParams: GLDParamDto[];
  targetConfig: TargetConfigParams;
  isAdmin: boolean;
};

export function GLDForm({
  handleSubmit,
  register,
  control,
  watch,
  setFocus,
  gameInstalls,
  minUsersPerGroup,
  gldParams,
  targetConfig,
  isAdmin
}: Props) {
  const isImportedSegments = Boolean(targetConfig.importedSegments);
  const isUserProperties = Boolean(targetConfig.userProperties.inputs.length);
  const isNewUsersDisabled = isImportedSegments || isUserProperties;

  return (
    <form onSubmit={handleSubmit}>
      <UserAllocationFormBlock gameInstalls={gameInstalls} minUsersPerGroup={minUsersPerGroup} />
      <InputGroup register={register} control={control} watch={watch} setFocus={setFocus} gldParams={gldParams} />
      <PercentUsersInput control={control} />
      <AdjustableUsersInput register={register} />
      <NewUsersInput register={register} disabled={isNewUsersDisabled} />
      {isAdmin && <StickyInput control={control} />}

      <NextButton />
    </form>
  );
}
