import React from 'react';
import cn from 'classnames';
import { Control, FieldArray } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';

import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';

import { NameCell } from '@pages/experimentDetails/components/summary/config/groups/atoms/NameCell';
import { DescriptionCell } from '@pages/experimentDetails/components/summary/config/groups/atoms/DescriptionCell';

import styles from '../GroupsConfig.module.scss';
import { FileUploadCellContainer } from '@components/forms/fileUploadCell/FileUploadCellContainer';

type Props = Pick<FormComponent<ExpGroupsConfigParams>, 'register' | 'errors'> & {
  field: FieldArray<ExpGroupsConfigParams>;
  editorEnabled: boolean;
  index: number;
  variables: string[];
  isAB: boolean;
  control: Control<ExpGroupsConfigParams>;
};

export function TRow({ variables, field, editorEnabled, index, register, errors, isAB, control }: Props) {
  const { entry, active } = field;

  const cells = variables.map((param) => {
    if (param === 'files') {
      return (
        <FileUploadCellContainer<ExpGroupsConfigParams>
          control={control}
          configName={field.name}
          fieldName={`groups.${index}.files`}
          key={`${param}.${index}`}
          isNonEditable={true}
        />
      );
    }

    if (!entry[param]) {
      return (
        <td className={styles.emptyValue} key={param}>
          {ExperimentFormFormatter.formatGLDValue(null)}
        </td>
      );
    }

    const { configValue, type } = entry[param];

    if (type === ExperimentObjectiveSessionType.SESSION) {
      const sessions = Object.keys(configValue);

      return (
        <td key={param}>
          {sessions.map((session, sessionIndex) => {
            const { value } = configValue[session];

            return (
              <p key={`${sessionIndex}_${session}`}>
                {session}: {String(value)}
              </p>
            );
          })}
        </td>
      );
    }

    const { value } = configValue;

    return (
      <td className={cn(value === '' && styles.emptyValue)} key={param}>
        {ExperimentFormFormatter.formatGLDValue(value)}
      </td>
    );
  });

  return (
    <tr className={cn(!active && styles.inactiveGroup)}>
      <NameCell field={field} errors={errors} register={register} index={index} editorEnabled={editorEnabled} />
      {!isAB && <DescriptionCell field={field} />}
      {cells}
    </tr>
  );
}
