import React from 'react';
import { Control, FieldArrayWithId, UseFormSetFocus } from 'react-hook-form';

import { FormComponent } from '@ui/hooks/form';
import { EditableCell } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/EditableCell';
import { InDevFormParams } from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { FileUploadCellContainer } from '@components/forms/fileUploadCell/FileUploadCellContainer';

type Props = Pick<FormComponent<InDevFormParams>, 'control'> & {
  control: Control<InDevFormParams>;
  setFocus: UseFormSetFocus<InDevFormParams>;
  configs: FieldArrayWithId<InDevFormParams, 'config'>[];
};

export function TBody({ configs, control, setFocus }: Props) {
  return (
    <tbody>
      <tr>
        {configs.map((config, index) => {
          const isFile = config.key === 'files';

          if (isFile) {
            return (
              <FileUploadCellContainer<InDevFormParams>
                key={config.key}
                fieldName={`config.${index}.value`}
                control={control}
                configName={'A'}
              />
            );
          }

          return <EditableCell key={config.key} control={control} index={index} />;
        })}
      </tr>
    </tbody>
  );
}
