import React from 'react';
import cn from 'classnames';

import { ExperimentObjectiveSessionType } from '@domain/enums/ExperimentObjectiveSessionType';
import { ExperimentFormFormatter } from '@app/mappers/experiment/ExperimentFormFormatter';

import styles from '../GroupsConfig.module.scss';
import { GenericConfigEntry } from '@domain/models/GenericConfigEntry';
import { ValueSummary } from '@components/forms/fileUploadCell/FileUploadCell';

type Props = {
  config: GenericConfigEntry;
  variables: string[];
};

export function TRow({ variables, config }: Props) {
  const { entry, active, files } = config;

  const cells = variables.map((param) => {
    if (param === 'files') {
      return (
        <td key={param}>
          <ValueSummary key={param} value={files!} />
        </td>
      );
    }

    if (!entry[param]) {
      return (
        <td className={styles.emptyValue} key={param}>
          {ExperimentFormFormatter.formatGLDValue(null)}
        </td>
      );
    }

    const { configValue, type } = entry[param];

    if (type === ExperimentObjectiveSessionType.SESSION) {
      const sessions = Object.keys(configValue);

      return (
        <td key={param}>
          {sessions.map((session, sessionIndex) => {
            const { value } = configValue[session];

            return (
              <p key={`${sessionIndex}_${session}`}>
                {session}: {String(value)}
              </p>
            );
          })}
        </td>
      );
    }

    const { value } = configValue;

    return (
      <td className={cn(value === '' && styles.emptyValue)} key={param}>
        {ExperimentFormFormatter.formatGLDValue(value)}
      </td>
    );
  });

  return <tr className={cn(!active && styles.inactiveGroup)}>{cells}</tr>;
}
