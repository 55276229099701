import { AppState } from '@infrastructure/store';

const getBasePath = (state: AppState) => state.auth;

const getStatus = (state: AppState) => getBasePath(state).status;
const getIsAuth = (state: AppState) => getBasePath(state).isAuth;
const getProfile = (state: AppState) => getBasePath(state).profile;
const getCurrentRole = (state: AppState) => {
  const profile = getProfile(state);
  return profile ? profile.getCurrentRole() : null;
};
const getIsAdmin = (state: AppState): boolean => {
  const profile = getProfile(state);

  if (!profile) {
    return false;
  }

  return profile.isAdmin();
};

export const authSelectors = {
  getStatus,
  getIsAuth,
  getProfile,
  getCurrentRole,
  getIsAdmin
};
