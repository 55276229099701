import { uniq } from 'lodash-es';

import { ExperimentDetailsMapper } from '@app/mappers/experiment/ExperimentDetailsMapper';
import { ChartMapper } from '@app/mappers/ChartMapper';
import { AppState } from '@infrastructure/store';
import { RequestStage } from '@infrastructure/store/types/actions';
import { ExpDefinitionParams } from '@domain/models/experimentDetails/ExpDefinitionParams';
import { ExpObjectiveConfigParams } from '@domain/models/experimentDetails/ExpObjectiveConfigParams';
import { ExpGroupsConfigParams } from '@domain/models/experimentDetails/ExpGroupsConfigParams';
import { DevPhaseParams } from '@domain/models/experimentDetails/devPhase/DevPhaseParams';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';
import { IterationDto } from '@domain/models/iteration/IterationDto';
import { IterationRequestParams } from '@domain/models/iteration/IterationRequestParams';
import { IterationAvailableMeta } from '@domain/models/iteration/IterationAvailableMeta';
import { UniqueId, UnixTimestamp } from '@domain/types';
import { IterationResultDto } from '@domain/models/iteration/IterationResultDto';
import { ChartType } from '@domain/enums/ChartType';
import { ChartDto } from '@domain/models/ChartDto';
import { AuditDto } from '@domain/models/experiment/AuditDto';
import { E_DAU_PREFIX } from '@domain/constants';

const getExperiment = (state: AppState): ExperimentDto => state.experimentDetails.experiment;
const getIsDau = (state: AppState): boolean => state.experimentDetails.experiment.experimentName.includes(E_DAU_PREFIX);

const getGameApp = (state: AppState) => {
  const { gameApp } = getExperiment(state);

  return gameApp;
};

const getDefaultRegionsData = (state: AppState) => state.experimentDetails.defaultRegions.data;
const getDefaultRegionsStatus = (state: AppState) => state.experimentDetails.defaultRegions.status;

const getViewStatus = (state: AppState): RequestStage => state.experimentDetails.viewStatus;

const getExpStatus = (state: AppState): RequestStage => state.experimentDetails.expStatus;

const getSummaryEditorStatus = (
  state: AppState
): {
  definitionUpdate: RequestStage;
  objectiveUpdate: RequestStage;
  groupsUpdate: RequestStage;
} => state.experimentDetails.summaryEditorStatus;
const getIsSummaryEditorLoading = (state: AppState): boolean => {
  const statuses = getSummaryEditorStatus(state);

  return Object.values(statuses).includes(RequestStage.REQUEST);
};

const getDevPhaseStatus = (state: AppState): RequestStage => state.experimentDetails.devPhaseStatus;

const getActionsStatus = (state: AppState): RequestStage => state.experimentDetails.actionsStatus;

export const getWinnerStatus = (state: AppState): RequestStage => {
  return state.experimentDetails.winnerStatus;
};

const getExpObjectiveById = (objectiveId: number) => (state: AppState) => {
  const { experimentObjectives } = getExperiment(state);
  return experimentObjectives.find(({ id }) => id === objectiveId);
};

const getObjectiveVariables = (objectiveId: number) => (state: AppState) => {
  const objective = getExpObjectiveById(objectiveId)(state);

  if (!objective) {
    return [];
  }

  const { configs } = objective;

  const variables: string[] = [];

  // get all variables keys from configs and return uniq
  configs.forEach(({ entry }) => variables.push(...Object.keys(entry)));

  if (configs.some(({ files }) => files?.length)) {
    variables.push('files');
  }

  return uniq(variables);
};

export const getSelectedObjectiveId = (state: AppState): number => {
  return Number(state.experimentDetails.selectedObjectiveId);
};

export const getObjectiveRegionNameById =
  (objectiveId: number) =>
  (state: AppState): string => {
    const objective = getExpObjectiveById(objectiveId)(state);

    return objective?.primaryRegion.name || '-';
  };

const getExpDefinitionParams = (state: AppState): ExpDefinitionParams => {
  const experiment = getExperiment(state);

  return ExperimentDetailsMapper.mapExpToDefinitionParams(experiment);
};

const getExpObjectiveConfigParams =
  (objectiveId: number) =>
  (state: AppState): ExpObjectiveConfigParams => {
    const selectedObjective = getExpObjectiveById(objectiveId)(state);
    return ExperimentDetailsMapper.mapExpObjectiveToFormParams(selectedObjective);
  };

const getGroupsConfigParams = (state: AppState): ExpGroupsConfigParams => {
  const objectiveId = getSelectedObjectiveId(state);
  const selectedObjective = getExpObjectiveById(objectiveId)(state);

  return ExperimentDetailsMapper.mapGroupsConfigToFormParams(selectedObjective);
};

const getDevPhaseParams = (state: AppState): DevPhaseParams => {
  const experiment = getExperiment(state);

  return ExperimentDetailsMapper.mapExpToDevPhaseParams(experiment);
};

const getGameStats = (state: AppState) => state.experimentDetails.gameStats.data;

const getGameStatsByPlatform = (state: AppState) => {
  const stats = getGameStats(state);
  const { platform } = getGameApp(state);

  return stats.filter((stat) => stat.platform === platform);
};
const getGameStatsFilter = (state: AppState) => state.experimentDetails.gameStats.filter;

const getIterations = (state: AppState): Record<UniqueId, IterationDto[]> => state.experimentDetails.iterations.data;
const getIterationsStatus = (state: AppState): RequestStage => state.experimentDetails.iterations.status;
const getIterationFilters = (state: AppState): IterationRequestParams => state.experimentDetails.iterations.filters;
const getIterationFiltersMeta = (state: AppState): IterationAvailableMeta => state.experimentDetails.iterations.meta;
const getSelectedIteration = (state: AppState): IterationDto => state.experimentDetails.iterations.selected;
const getSelectedNextCheckDate = (state: AppState): UnixTimestamp => state.experimentDetails.iterations.nextCheckDate;

const getIterationsByFilter = (state: AppState): IterationDto[] => {
  const { experimentObjectives } = getExperiment(state);
  const filters = getIterationFilters(state);

  const objective = experimentObjectives.find(({ regions }) => {
    const regionsNames = regions.map((region) => region.name);

    return regionsNames.includes(filters.region);
  });
  const iterations = getIterations(state);

  return objective ? iterations[objective.id] : [];
};

const getIterationResult = (state: AppState): IterationResultDto[] => state.experimentDetails.iterations.result.data;
const getIterationResultFromCache =
  (key: string) =>
  (state: AppState): IterationResultDto[] =>
    state.experimentDetails.iterations.result.cache[key];
const getIterationResultStatus = (state: AppState): RequestStage => state.experimentDetails.iterations.result.status;
const getMetricKeys = (state: AppState): string[] => state.experimentDetails.iterations.metricKeys;
const getSelectedRows = (state: AppState): string[] => state.experimentDetails.iterations.selectedRows;
const getFilteredChartByType =
  (type: ChartType) =>
  (state: AppState): ChartDto => {
    const chart = state.experimentDetails.charts.data[type];
    const groups = state.experimentDetails.iterations.selectedRows;

    return ChartMapper.filterRawChartDataByGroup(chart, groups);
  };

const getChartByType = (type: ChartType) => (state: AppState) => state.experimentDetails.charts.data[type];
const getChartFromCache = (key: string) => (state: AppState) => state.experimentDetails.charts.cache[key];
const getChartsStatus = (state: AppState): RequestStage => state.experimentDetails.charts.status;
const getAudit = (state: AppState): AuditDto[] => state.experimentDetails.audit.data;
const getAuditStatus = (state: AppState): RequestStage => state.experimentDetails.audit.status;
const getLogList = (state: AppState): string[] => state.experimentDetails.logs.list.data;
const getLogListStatus = (state: AppState): RequestStage => state.experimentDetails.logs.list.status;
const getSelectedLog = (state: AppState): string => state.experimentDetails.logs.selected.data;
const getSelectedLogName = (state: AppState): string => state.experimentDetails.logs.selected.name;
const getSelectedLogStatus = (state: AppState): RequestStage => state.experimentDetails.logs.selected.status;
const getRequestAnalysisStatus = (state: AppState): RequestStage =>
  state.experimentDetails.iterations.requestAnalysisStatus;

export const experimentDetailsSelectors = {
  getExperiment,
  getIsDau,
  getDefaultRegionsData,
  getDefaultRegionsStatus,
  getGameApp,

  getViewStatus,
  getExpStatus,
  getDevPhaseStatus,
  getActionsStatus,
  getSummaryEditorStatus,
  getWinnerStatus,

  getIsSummaryEditorLoading,

  getObjectiveRegionNameById,
  getSelectedObjectiveId,
  getExpObjectiveById,
  getObjectiveVariables,

  getExpDefinitionParams,
  getExpObjectiveConfigParams,
  getGroupsConfigParams,

  getDevPhaseParams,

  getGameStatsByPlatform,
  getGameStatsFilter,

  getGameStats,

  getIterations,
  getIterationsStatus,
  getIterationFilters,
  getIterationFiltersMeta,
  getIterationsByFilter,
  getSelectedIteration,
  getSelectedNextCheckDate,
  getIterationResult,
  getIterationResultFromCache,
  getIterationResultStatus,
  getMetricKeys,
  getSelectedRows,
  getFilteredChartByType,
  getChartByType,
  getChartFromCache,
  getChartsStatus,
  getAudit,
  getAuditStatus,
  getLogList,
  getLogListStatus,
  getSelectedLogName,
  getSelectedLog,
  getSelectedLogStatus,
  getRequestAnalysisStatus
};
