import React, { useCallback } from 'react';
import { Control, useFieldArray, UseFormSetFocus, UseFormWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash-es';
import { Button, ButtonVariant } from 'crazy-ui-next';

import {
  InDevFormParams,
  InDevConfigFormInput
} from '@pages/directConfigDetails/components/summary/config/inDevForm/types/InDevFormParams';
import { UniqueId } from '@domain/types';
import { Thead } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/Thead';
import { TBody } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/TBody';
import { AddParamBtn } from '@pages/directConfigDetails/components/summary/config/inDevForm/sections/objectiveConfig/configInput/atoms/AddParamBtn';
import { ReactComponent as PlusSvg } from '@assets/svg/plus.svg';

import styles from './ConfigInput.module.scss';

type Props = {
  control: Control<InDevFormParams>;
  watch: UseFormWatch<InDevFormParams>;
  setFocus: UseFormSetFocus<InDevFormParams>;
};

export function ConfigInputContainer({ control, watch, setFocus }: Props) {
  const configs = watch('config');
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'config'
  });

  const isFilesExist = fields.some((config) => config.key === 'files');

  const handleAdd = useCallback(
    (param: string) => {
      const newConfig = new InDevConfigFormInput(param, '');
      append(newConfig);
    },
    [append]
  );

  const handleAddFiles = useCallback(() => {
    const newConfig = new InDevConfigFormInput('files', '');
    append(newConfig);
  }, [append]);

  const handleRemove = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const { index } = e.currentTarget.dataset;

      index && remove(parseInt(index, 10));
    },
    [remove]
  );

  const handleUpdateKey = useCallback(
    (index: UniqueId, key: string) => {
      const config = cloneDeep(configs[index]);

      config.key = key;

      update(index, config);
    },
    [update, configs]
  );

  return (
    <div className={styles.inputGroupWrapper}>
      <Button onClick={handleAddFiles} variant={ButtonVariant.PRIMARY} icon={<PlusSvg />} disabled={isFilesExist}>
        Add Files
      </Button>
      <div className={styles.inputGroup}>
        <table className={styles.table}>
          <Thead watch={watch} configs={fields} handleRemoveKey={handleRemove} handleUpdateKey={handleUpdateKey} />
          <TBody control={control} configs={fields} setFocus={setFocus} />
        </table>
        <AddParamBtn handleAddParam={handleAdd} watch={watch} />
      </div>
    </div>
  );
}
